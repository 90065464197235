<template>
    <div class="reqdetail">
<!--        需求基本信息-->
        <div class="reqcard">
            <div class="reqinfo">
                <div class="p1">{{this.item.name}}</div>
                <div class="p2">
                    <img src="assets/order_card_location.png"/>
                    <div class="location">{{this.item.address}}</div>
                </div>
            </div>

            <div class="reqcard_content">
                <div class="reqcard_content_item">
                    <div class="p4">服务日期</div>
                    <div class="p3">{{this.item.time}}</div>
                    <div class="p5">{{this.item.categoryName}}</div>
                </div>
                <div class="reqcard_content_item" v-if="item.pictures!=null && item.pictures.length >0">
                    <img v-for="(imgitem,index) in item.pictures" :key="index" :src="imgitem" @click="showImg(imgitem,item.pictures)" />
<!--                    <img src="assets/demo_i1.png"/>-->
<!--                    <img src="assets/demo_i1.png"/>-->
<!--                    <img src="assets/demo_i1.png"/>-->
                </div>
                <div class="reqcard_content_item reqcard_content_item_extend">
                    <div class="p6">投标说明&要求</div>
                    <div class="p7">
                        <div v-html="item.info"></div>
                    </div>
                </div>
            </div>
        </div>

<!--        投标人数-->
        <div class="reqcard">
            <div class="people_title">已投标人数</div>
            <div class="people_imgs" v-if="item.campaigns!=null && item.campaigns.length >0 ">
                <img v-for="(img,index) in item.campaigns" :key="index" :src="img.avatar" :class="index==0?'':'imgsHeader'"/>
<!--                <img class="imgsHeader" src="assets/demo_head.png"/>-->
<!--                <img class="imgsHeader" src="assets/logo.png"/>-->
<!--                <img class="imgsHeader" src="assets/demo_head.png"/>-->
            </div>
            <div v-else class="nopeople">
                暂无人员投标
            </div>
        </div>

<!--        我要投标-->
        <div class="reqcard" v-if="userType==0">
            <div class="people_title">我要投标</div>
            <div class="reqcard_money">
                <div class="lable">投标金额</div>
                <input type="text" v-model="amount" placeholder="金额"/>
                <div class="btn" @click="confirm">
                    确认
                </div>
            </div>
            <div class="tips"> <span style="color:red">*</span> 每个账号每个需求只能投标一次</div>
        </div>

        <!--        返回home-->
        <div class="reqlist_back_home" v-on:click="toReqList">
            <img src="assets/req_back.png"/>
        </div>

    </div>
</template>

<script>
    import keys from "../../xyg/utils/keys";
    import {Toast} from 'mint-ui';
    export default {
        name: "ReqDetail",
        data(){
            return{
                info:'',
                userType:'',
                // item:{
                //     campaigns:[],
                //     pictures:[]
                // },
                item:{},
                amount:0,
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                document.title="投标详情";
                this.userType=localStorage.getItem(keys.userType);
                let id=this.$route.query.pid;
                let openId=localStorage.getItem(keys.openId);
                let data={openId:openId,pid:id};
                let url="/api/product/findDetail";
                this.$post(url,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            try{
                                //暂存
                                this.item=rsp.data;
                                this.info=rsp.data.info;
                                console.log('item:',this.item);
                            }
                            catch (e) {
                                console.log('error:'+e);
                            }
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            toReqList(){
                let cardType=this.$route.query.cardType;
                this.$router.push({path:"/ReqList",query:{
                        cardType:cardType,
                    }
                })
            },
            confirm(){
                if(this.amount==0){
                    Toast({
                        message: '请输入报价金额',
                        iconClass: 'mintui mintui-success',
                        duration: 1000
                    });

                    return;
                }
                let id=this.$route.query.pid;
                let openId=localStorage.getItem(keys.openId);
                let data={openId:openId,pid:id,orderAmount:this.amount};
                let reqUrl='/api/campaign/quotation';
                this.$post(reqUrl,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            Toast({
                                message: '提交成功',
                                iconClass: 'mintui mintui-success',
                                duration: 1000
                            });
                            this.amount=0;
                            this.toReqList();
                        }
                        else{
                            Toast({
                                message: rsp.message,
                                iconClass: 'mintui mintui-success',
                                duration: 1000
                            });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })

            },
            showImg(path,imgs){
                if(imgs.length>0){
                    this.$wx.previewImage({
                        current:path,
                        urls:imgs
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .reqdetail{
        width: 750px;
    }

    .reqdetail .reqcard{
        border-radius: 10px;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
    }
    .reqdetail .reqcard .reqinfo{
        margin-left: 30px;
        margin-top: 16px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
    }
    .reqdetail .reqcard .reqinfo .p1{
        font-size: 26px;
        font-weight: bold;
    }
    .reqdetail .reqcard .reqinfo .p2{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 22px;
        color: #A79F9F;
        margin-top: 10px;
        margin-bottom: 16px;
    }
    .p2 .location{
        margin-left: 10px;
    }
    .reqdetail .reqcard .reqinfo .p2 img{
        width: 24px;
        height: 24px;
    }
    .reqcard .reqcard_content{
        border-top: 2px #E4E4E4 dotted;
    }

    .reqcard .reqcard_content .reqcard_content_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 26px;
        margin-top: 16px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .reqcard_content_item .p4{
        color: #A79F9F;
    }

    .reqcard_content_item .p3{
        flex: 1;
        margin-left: 16px;
    }

    .reqcard_content_item .p5{
        /*margin-right: 30px;*/
    }

    .reqcard_content_item img{
        margin-right: 20px;
        width: 150px;
        height: 150px;
    }

    .reqcard_content_item .p6{
        color: #A79F9F;
    }

    .reqcard_content_item .p7{
        margin-top: 16px;
    }

    .reqcard_content_item_extend{
        padding-bottom: 16px;
        flex-direction: column !important;
        align-items: unset !important;
    }

    .reqdetail .people_title{
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
    }

    .reqdetail .people_imgs{
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
        height: 80px;
        margin-bottom: 30px;
    }
    .reqdetail .people_imgs img{
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border:0px;
    }

    .imgsHeader{
        margin-left: -40px;
    }

    .reqcard_money{
        letter-spacing: 2px;
        margin-top: 30px;
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        align-items: center;
        margin-bottom: 30px;
    }

    .tips{
        color: #A79F9F;
        font-size: 26px;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    .reqcard_money .lable{
        color: #A79F9F;
        width: 120px;
        font-size: 26px;
    }

    .reqcard_money .money1{
        flex:1;
        margin-left: 20px;
        margin-right: 22px;
    }

    .reqcard_money input[type=text]{
        display: block;
        padding:0;
        /*width: 260px;*/
        height: 88px;
        border:2px #DBDBDB solid;
        border-radius: 10px;
        flex:1;
        width: 100%;

        margin-left: 20px;
        margin-right: 22px;
        font-size: 38px;
        padding-left: 10px;
        letter-spacing: 2px;
    }

    .reqcard_money .btn{
        width: 200px;
        height: 80px;
        background-color: #226DF8;
        box-shadow: 0px 2px 4px rgba(117,164,245,0.5);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;

        letter-spacing: 2px;
    }

    .reqdetail .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqdetail .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }

    .nopeople{
        color: #A79F9F;
        margin-left: 30px;
        margin-top: 16px;
        font-size: 26px;
        margin-bottom: 16px;
    }
</style>
